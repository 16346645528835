.MuiGrid-container.detail-content {
    width: 100%;
    margin: 0;
    padding: 24px;
    display: flex;
    flex-wrap: nowrap;
    background-color: var(--secondary-background-color);
}
.MuiGrid-item.left-container, .MuiGrid-item.right-container {
    margin: 0;
    padding: 20px;
    background-color: var(--primary-white);
    border-radius: 16px;
}
.MuiGrid-item.left-container {
    margin-right: 12px;

}
.MuiGrid-item.right-container {
    margin-left: 12px;
}
.MuiGrid-item.left-container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}
.MuiGrid-item.left-container .date-area.first-line {
    margin-top: 20px;
}
.MuiGrid-item.left-container .date-area,
.MuiGrid-item.left-container .time-area
{
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-text-dark-blue);
}
.MuiGrid-item.left-container .time-area {
    color: var(--primary-white);
    width: 90px;
    height: 90px;
    margin-top: 72px;
    border-radius: 50%;
    padding-top: 30px;
    background-color: var(--secondary-backgroud-light-blue);
    box-shadow: 0 0 0 15px var(--secondary-backgroud-light-blue-1),
                0 0 0 30px var(--secondary-backgroud-light-blue-2);
    text-align: center;
}
.MuiGrid-item.left-container .status-area {
    position: absolute;
    bottom: 70px;
}
.MuiGrid-item.left-container .update-btn {
    position: absolute;
    bottom: 30px;
}
.MuiGrid-item.right-container .member-area {
    border-bottom: 1px solid var(--primary-text-line-grey);
    width: 100%;
    display: flex;

}
.MuiGrid-item.right-container .member-area:last-child {
    border-bottom: none;
}
.MuiTableCell-root.table-td-title {
    width: 30%;
    border-bottom: none;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-text-dark-blue);
}
.MuiTableCell-root.table-td-content {
    width: 70%;
    border-bottom: none;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-text-dark-blue);
    font-weight: bold;
    position: relative;
}
.member-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.member-image {
    border-radius: 50%;
    overflow: hidden;
    width: 70%;
    max-width: 130px;
}
.MuiButton-outlinedPrimary.into-btn {
    border-color: var(--secondary-backgroud-light-blue);
    color: var(--secondary-backgroud-light-blue);
    position: absolute;
    right: 32px;
    bottom: 0;
}