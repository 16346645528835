.add-info-section {
    padding: 2rem 0;
}
.add-info-left {
    padding-right: 0.5rem;
    border-right: 1px solid #0000001f;
}
.add-info-left .left-content {
    margin: 2rem auto 0;
    width: 300px;
    display: flex;
}
.add-info-left .left-content .big-icon {
    width: 100px !important;
    height: 100px !important;
    margin: 1.5rem auto;
}
.add-info-left .left-content .upload-btn {
    margin: 3.5rem auto 0;
    width: 60%;
    background-color: #388EF2;
}
.add-info-right {
    padding-left: 1.5rem;
}