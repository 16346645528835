.login-container {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
}

.login-container .left-side {
    width: calc(100% - 400px);
    background-color: #F2F5F9;
    padding: 40px 36px;
}

.login-container .left-side .image-content {
    height: 80%;
    display: flex;
}

.login-container .left-side .image-content img {
    width: 76%;
    margin: 0 auto;
}

.login-container .right-side {
    width: 400px;
}

.paper {
    background-color: var(--primary-blue);
    height: 100%;
}

.paper .form {
    width: 70%;
    height: 100%;
    margin: 0 auto;
    padding: 100px 0 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.paper .form .form-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-white);
    margin-bottom: 16px;
}

.paper .form input {
    outline: none;
    border: 2px solid var(--primary-aqua);
    padding: 8px;
    border-radius: 4px;
    margin: 16px 0 8px;
    background-color: var(--primary-white);
}

.paper .form .password-input {
    position: relative;
}

.paper .form .password-input > input {
    padding-right: 40px;
}

.paper .form .password-input > .password-icon-area {
    position: absolute;
    right: 0;
    top: 36px;
}

.paper .form .login-actions {
    display: flex;
}

.paper .form .submit {
    background-color: var(--primary-aqua);
    border: 2px solid var(--primary-aqua);
    color: var(--primary-blue);
    margin-top: 16px;
    font-weight: bold;
}

.paper .form .login-actions {
    margin-top: 8px;

}

.paper .form .login-actions .remember-me {
    width: 50%;
}

.paper .form .login-actions .remember-me span {
    color: var(--primary-aqua);
    font-size: 10px;
}

.paper .form .login-actions .forget-psw {
    color: var(--primary-white);
    width: 50%;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.paper .form .sign-up {
    position: absolute;
    bottom: 24px;
    margin: 0 auto;
    color: var(--primary-aqua);
    width: 100%;
    text-align: center;
}

.paper .form .sign-up .sign-up-label {
    font-size: 12px;
    color: var(--primary-aqua);
}

.paper .form .sign-up .sign-up-link {
    font-size: 12px;
    color: var(--primary-white);
    margin-left: 16px;
}

.MuiInput-underline:before {
    border-bottom: 0px solid transparent !important;
}
