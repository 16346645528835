.new-survey-step.MuiPaper-root {
    background-color: var(--secondary-background-color);
}
.survey-paper.MuiPaper-root {
    margin: 32px 8%;
    border-radius: 8px;
}
.survey-paper.MuiPaper-root form > div:first-child,
.survey-paper.MuiPaper-root form > div:last-child {
    border-radius: 8px;
}
.survey-paper.MuiPaper-root form > div:last-child {
    padding-bottom: 16px;
}
.survey-paper.MuiPaper-root, .survey-section.MuiContainer-root, .btnGroup-container.MuiContainer-root {
    background-color: var(--primary-white);
}
.survey-section {
    padding: 32px 24px 24px;
}
.survey-section .step-title {
    color: var(--primary-blue);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}
.survey-section .title-divider {
    margin-top: 8px;
    margin-bottom: 16px;
}