.quick-popup {
    background-color: transparent;
}

.quick-popup > div {
    justify-content: flex-start;
    align-items: flex-end;
}

.quick-popup > div > div {
    margin: 0 0 0 238px;
    width: 300px;
    min-height: 370px;
    bottom: 24px;;
}

.quick-popup > div > div .dialog-title {
    padding: 12px 12px 0;
    height: 38px;
}

.quick-popup > div > div .dialog-title h2 {
    position: relative;
}

.quick-popup > div > div .dialog-title .close-icon-btn {
    border-radius: 50%;
    padding: 0;
    color: var(--primary-white);
    border: 1px solid var(--primary-white);
    position: absolute;
    right: 0;
}

.quick-popup > div > div .dialog-title .close-icon-btn span svg {
    width: 14px;
    height: 14px;
}

.quick-popup > div > div .popup-content {
    width: 100%;
    height: 308px;
    position: relative;}

.quick-popup .popup-content .form-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-white);
    font-weight: bold;
    margin-bottom: 20px;
}

.quick-popup .popup-content .form-item.hidden {
    display: none;
}

.quick-popup .popup-content .comment-area {
    display: flex;
    color: var(--primary-white);
    font-weight: bold;
    margin-bottom: 24px;
    flex-direction: column;
}

.quick-popup .popup-content .comment-area .comment-field {
    background: var(--primary-white);
    padding-left: 8px;
    border-radius: 6px;
    margin: 8px 0 0;
    color: var(--primary-blue);
    font-size: 12px;
}

.quick-popup .popup-content .form-item .radio-group {
    display: flex;
    flex-direction: row;
}

.quick-popup .popup-content .form-item .radio-group .radio-item:last-child{
    margin-right: 0;
}

.quick-popup .popup-content .form-item .radio-group .radio-item > span {
    color: var(--primary-white);
}

.quick-popup .popup-content .form-item .radio-group .radio-item > span svg {
    width: 18px;
    height: 18px;
}

.quick-popup .popup-content .form-item .type-select {
    background: var(--primary-white);
    padding-left: 8px;
    border-radius: 6px;
    margin: 0;
    width: calc(100% - 106px);
    color: var(--primary-blue);
    font-size: 12px;
}
.quick-popup .popup-content .form-item .type-select select,
.quick-popup .popup-content .form-item .type-select input,
.quick-popup .popup-content .comment-area .comment-field textarea {
    color: var(--primary-text-dark-blue);
}

.form-item > div::before, .form-item > div::after, 
.comment-field > div:hover:not(.Mui-disabled)::before, .comment-field > div:hover:not(.Mui-disabled)::after {
    border-bottom: none;
}

form .form-btn {
    background-color: var(--primary-blue);
    color: var(--primary-white);
    margin-right: 16px;
    padding:4px 20px;
    border-radius: 4px;
    font-size: 12px;
}

form .form-submit-btn {
    margin-right: 0;
    position: absolute;
    bottom: 0;
    right: 0;
}