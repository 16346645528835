.main-page {
    background-color: var(--secondary-background-color);
}
.card-box.MuiPaper-rounded {
    position: relative;
    background-color: transparent !important;
    height: 100%;
    box-shadow: none !important;
    border-radius: 8px;
}
.card-box.MuiPaper-rounded > button {
    border-radius: 8px;
}
.card-box .card-area {
    padding-bottom: 52px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.card-box .card-image {
    --width-image: 100%;
    width: var(--width-image);
    height: var(--width-image) * 3 / 4;
    border-radius: 10px;
}
.card-box .card-content .card-content-title {
    font-size: 18px;
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.card-box .card-action {
    position: absolute;
    bottom: 0;
}